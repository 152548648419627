import React from 'react'
import router from 'next/router'
import MainLayout from '@/components/Layout/main-layout'
import styles from '@/components/OfferPage/components/OfferList.module.css'
import ButtonForm from '@/components/Main/ButtonForm/ButtonForm'

const ErrorPage = () => {
  const goBack = () => {
    router.replace('/')
  }

  return (
    <MainLayout title="¡Ups! Ocurrió un error inesperado | tuefectivo.uy – Encontrá tus ofertas de préstamo">
      <section className="bg-bg-3 m-1">
        <div className="container">
          <div className="justify-content-center row float-xl-none mt-xl-8">
            <div className="py-10 py-lg-20 col-md-8 col-sm-9 container col-lg-5 col-xl-5">
              <div className="mb-16 g-0">
                <div className="d-flex justify-content-center mb-9">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="110"
                    height="110"
                    viewBox="0 0 392.533 392.533"
                    style={{ enableBackground: 'new 0 0 392.533 392.533' }}
                    xmlSpace="preserve"
                  >
                    <path
                      style={{ fill: '#ffffff' }}
                      d="M359.822,21.786H32.711c-6.012,0-10.925,4.848-10.925,10.925v327.111
	c0,6.012,4.848,10.925,10.925,10.925h327.111c6.012,0,10.925-4.848,10.925-10.925V32.711
	C370.747,26.699,365.834,21.786,359.822,21.786z"
                    />
                    <path
                      style={{ fill: '#e93a7d' }}
                      d="M43.636,43.636v58.44h56.242c6.012,0,10.925,4.848,10.925,10.925c0,6.012-4.848,10.925-10.925,10.925
	H43.636v24.76h24.824c6.012,0,10.925,4.848,10.925,10.925c0,6.012-4.848,10.925-10.925,10.925H43.636v178.554h305.325V43.636H43.636
	z"
                    />
                    <path
                      style={{ fill: '#56ace0' }}
                      d="M255.483,177.196c0-33.487-27.216-60.768-60.768-60.768c-33.487,0-60.768,27.216-60.768,60.768
	c0,33.487,27.216,60.768,60.768,60.768C228.267,237.834,255.483,210.618,255.483,177.196z"
                    />
                    <g>
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M194.78,94.642c-45.511,0-82.554,37.042-82.554,82.554s37.042,82.554,82.554,82.554
		s82.554-37.042,82.554-82.554C277.333,131.685,240.291,94.642,194.78,94.642z M134.077,177.196
		c0-33.487,27.216-60.768,60.768-60.768c33.487,0,60.768,27.216,60.768,60.768c0,33.487-27.216,60.768-60.768,60.768
		C161.358,237.834,134.077,210.618,134.077,177.196z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M359.758,0H32.711C14.675,0,0,14.675,0,32.711v327.111c0,18.036,14.675,32.711,32.711,32.711
		h327.111c18.036,0,32.711-14.675,32.711-32.776V32.711C392.533,14.675,377.859,0,359.758,0z M370.683,359.758
		c0,6.012-4.849,10.925-10.925,10.925H32.711c-6.012,0-10.925-4.848-10.925-10.925V32.711c0-6.012,4.848-10.925,10.925-10.925
		h327.111c6.012,0,10.925,4.848,10.925,10.925v327.046H370.683z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M79.515,58.828h-11.96c-6.012,0-10.925,4.848-10.925,10.925s4.848,10.925,10.925,10.925h11.96
		c6.012,0,10.925-4.848,10.925-10.925S85.527,58.828,79.515,58.828z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M137.18,58.828h-12.024c-6.012,0-10.925,4.848-10.925,10.925s4.848,10.925,10.925,10.925h11.96
		c6.012,0,10.925-4.848,10.925-10.925S143.192,58.828,137.18,58.828z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M194.715,58.828h-11.96c-6.012,0-10.925,4.848-10.925,10.925s4.848,10.925,10.925,10.925h11.96
		c6.012,0,10.925-4.848,10.925-10.925S200.727,58.828,194.715,58.828z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M137.438,314.117v-11.442h13.964v11.442h7.434v12.606h-7.434v15.58h-14.093v-15.58h-26.246v-11.119
		l23.273-38.659h15.903l-22.174,37.172H137.438z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M257.034,314.117v-11.442h14.093v11.442h7.434v12.606h-7.434v15.58h-14.093v-15.58h-26.246v-11.119
		l23.273-38.659h15.903l-22.174,37.172H257.034z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M166.659,161.552h5.301c6.012,0,10.925-4.848,10.925-10.925c0-6.077-4.848-10.925-10.925-10.925
		h-5.301c-6.012,0-10.925,4.848-10.925,10.925C155.798,156.703,160.646,161.552,166.659,161.552z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M217.535,161.552h5.301c6.012,0,10.925-4.848,10.925-10.925c0-6.077-4.848-10.925-10.925-10.925
		h-5.301c-6.012,0-10.925,4.848-10.925,10.925C206.61,156.703,211.459,161.552,217.535,161.552z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M159.806,211.329c4.267,4.267,11.119,4.267,15.451,0c5.236-5.236,12.218-8.145,19.653-8.145
		s13.964,3.297,19.523,8.145c7.046,6.012,13.317,2.133,15.451,0c4.267-4.267,4.267-11.119,0-15.451
		c-9.374-9.374-21.786-14.545-34.974-14.545c-13.188,0-25.665,5.172-34.974,14.545C155.539,200.145,155.539,207.063,159.806,211.329
		z"
                      />
                      <path
                        style={{ fill: '#1e0e62' }}
                        d="M195.168,274.489c-19.071,0-28.121,14.352-28.121,35.168l0,0l0,0
		c0,23.467,10.796,35.168,28.121,35.168c19.071,0,28.121-14.352,28.121-35.168l0,0l0,0
		C223.289,286.125,212.558,274.489,195.168,274.489z M195.168,332.283c-11.895,0-13.188-15.774-13.188-22.626l0,0
		c0-6.853,0.776-22.626,13.188-22.626c11.895,0,13.188,15.774,13.188,22.626l0,0C208.485,316.509,207.709,332.283,195.168,332.283z"
                      />
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>
                <h2
                  className={`mb-4 ${styles.form2Heading}`}
                  style={{ fontSize: '2rem', fontFamily: 'Baloo 2' }}
                >
                  ¡Ups! Ocurrió un error inesperado procesando tu información,
                  por favor vuelve a intentarlo más tarde
                </h2>
                <p
                  className="mb-8 fs-4 fw-4"
                  style={{
                    color: 'gray',
                    fontSize: '18px !important',
                    fontFamily: 'Baloo 2',
                  }}
                >
                  Parece que se produjo un error durante el procesamiento de la
                  información. Vuelve a intentarlo más tarde.
                </p>
                <ButtonForm title="Volver" clickFn={goBack} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default ErrorPage
